import React from 'react';

export default function Contact() {

    return (
        <div className="section testimonials no-border-bottom gray load-twitter">
            <div className="disable-slider"></div>
            <div className="inner">
                <div className="container center">
                    <div className="ten columns offset-three">
                        <h2>We&prime;d love to work with you</h2>
                        <p className="light">
                            Please email us: <a href="mailto:info@cdestrat.com">info@cdestrat.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

}

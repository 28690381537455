import React from 'react';
import ImageBackground from './ImageBackground';

export default function Home() {

    return (
        <div className="section top lazy purple">

            <ImageBackground />

            <div className="container hero-parallax" style={{ top: 0 }}>
                <div className="sixteen columns">

                    <h1 className="title">
                        CDE Strategies
                    </h1>

                    <p className="tagline">
                        <b>CDE Strategies</b> offers a wide variety of fundraising services for political campaigns, political organizations, and nonprofits.
                        Our approach combines art and science, data and relationships, creative long-term strategies, relentless focus on detail, and goal-surpassing efforts.
                    </p>

                </div>
            </div>

            <div className="sep-dual sep-gray"></div>
            <div className="arrow"></div>
        </div>
    );

}

import React from 'react';

export default function ImageBackground() {

    const imageUrl = 'https://images.pexels.com/photos/1572386/pexels-photo-1572386.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260';

    return (
        <div className="spread lazy"
            data-background={imageUrl}
            style={{ backgroundImage: `url("${imageUrl}")` }}>
        </div>
    );

}
